<script>
import { regionToCompany } from '@/utils/regions'
import { Settings } from 'luxon'

Settings.defaultLocale = 'sv'
Settings.defaultZoneName = 'utc'

export default {
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === 'function' ? title(this.$store) : title
      return title ? `${title} | Register` : 'Register'
    },
  },
  data() {
    return {
      refreshing: false,
      registration: null,
      updateExists: false,
    }
  },
  computed: {
    companyCode() {
      return regionToCompany(this.$store.getters['app/currentRegionCode'])
    },
  },
  watch: {
    companyCode: {
      immediate: true,
      handler: function (next) {
        document.body.setAttribute('data-theme', next)
      },
    },
  },
  created() {
    // Listen for swUpdated event and display refresh snackbar as required.
    document.addEventListener('swUpdated', this.showRefreshUI, { once: true })
    // Refresh all open app tabs when a new service worker is installed.
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.refreshing) return
        this.refreshing = true
        window.location.reload()
      })
    }
  },
  methods: {
    showRefreshUI(e) {
      // Display a button inviting the user to refresh/reload the app due
      // to an app update being available.
      // The new service worker is installed, but not yet active.
      // Store the ServiceWorkerRegistration instance for later use.
      this.registration = e.detail
      this.updateExists = true
    },
    refreshApp() {
      // Handle a user tap on the update app button.
      this.updateExists = false
      // Protect against missing registration.waiting.
      if (!this.registration || !this.registration.waiting) {
        return
      }
      this.registration.waiting.postMessage('skipWaiting')
    },
  },
}
</script>

<template>
  <div id="app">
    <div v-if="updateExists" class="flex p-2 border-b border-gray-600">
      <button
        class="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded mx-auto"
        @click="refreshApp"
      >
        Ny version finns att hämta! Klicka här för att uppdatera
      </button>
    </div>
    <RouterView />
  </div>
</template>

<style lang="scss">
@import '~nprogress/nprogress.css';

:root {
  --sortera_hue: 94;
  --sortera_main: hsl(var(--sortera_hue), 50%, 51%);
  --sortera_focus: hsla(var(--sortera_hue), 50%, 51%, 0.25);
  --sortera_hover: hsl(var(--sortera_hue), 50%, 47%);
  --sortera_active: hsl(var(--sortera_hue), 50%, 43%);
  --sorterastockholm_hue: 94;
  --sorterastockholm_main: hsl(var(--sortera_hue), 50%, 51%);
  --sorterastockholm_focus: hsla(var(--sortera_hue), 50%, 51%, 0.25);
  --sorterastockholm_hover: hsl(var(--sortera_hue), 50%, 47%);
  --sorterastockholm_active: hsl(var(--sortera_hue), 50%, 43%);
  --sorteragoteborg_hue: 202;
  --sorteragoteborg_main: hsl(var(--sorteragoteborg_hue), 100%, 37%);
  --sorteragoteborg_focus: hsla(var(--sorteragoteborg_hue), 100%, 37%, 0.25);
  --sorteragoteborg_hover: hsl(var(--sorteragoteborg_hue), 100%, 33%);
  --sorteragoteborg_active: hsl(var(--sorteragoteborg_hue), 100%, 29%);
  --sorteramalmo_hue: 190;
  --sorteramalmo_main: hsl(var(--sorteramalmo_hue), 63%, 76%);
  --sorteramalmo_focus: hsla(var(--sorteramalmo_hue), 63%, 76%, 0.25);
  --sorteramalmo_hover: hsl(var(--sorteramalmo_hue), 63%, 72%);
  --sorteramalmo_active: hsl(var(--sorteramalmo_hue), 63%, 68%);
  --bigbag_hue: 23;
  --bigbag_main: hsl(var(--bigbag_hue), 92%, 56%);
  --bigbag_focus: hsla(var(--bigbag_hue), 92%, 56%, 0.25);
  --bigbag_hover: hsl(var(--bigbag_hue), 92%, 52%);
  --bigbag_active: hsl(var(--bigbag_hue), 92%, 48%);
  --akerisacken_hue: 0;
  --akerisacken_main: hsl(var(--akerisacken_hue), 0%, 60%);
  --akerisacken_focus: hsla(var(--akerisacken_hue), 0%, 60%, 0.25);
  --akerisacken_hover: hsl(var(--akerisacken_hue), 0%, 56%);
  --akerisacken_active: hsl(var(--akerisacken_hue), 0%, 52%);
  --theme_main: var(--sortera_main);
  --theme_focus: var(--sortera_focus);
  --theme_hover: var(--sortera_hover);
  --theme_active: var(--sortera_active);
}

body {
  @apply font-normal text-gray-600 leading-normal bg-gray-100;

  --theme_main: hsl(0, 0%, 0%);

  &[data-theme^='sortera'] {
    --theme_main: var(--sortera_main);
    --theme_focus: var(--sortera_focus);
    --theme_hover: var(--sortera_hover);
    --theme_active: var(--sortera_active);
  }
  &[data-theme='bigbag'] {
    --theme_main: var(--bigbag_main);
    --theme_focus: var(--bigbag_focus);
    --theme_hover: var(--bigbag_hover);
    --theme_active: var(--bigbag_active);
  }
  &[data-theme='akerisacken'] {
    --theme_main: var(--akerisacken_main);
    --theme_focus: var(--akerisacken_focus);
    --theme_hover: var(--akerisacken_hover);
    --theme_active: var(--akerisacken_active);
  }
  &[data-theme='sorteragoteborg'] {
    --theme_main: var(--sorteragoteborg_main);
    --theme_focus: var(--sorteragoteborg_focus);
    --theme_hover: var(--sorteragoteborg_hover);
    --theme_active: var(--sorteragoteborg_active);
  }
  &[data-theme='sorteramalmo'] {
    --theme_main: var(--sorteramalmo_main);
    --theme_focus: var(--sorteramalmo_focus);
    --theme_hover: var(--sorteramalmo_hover);
    --theme_active: var(--sorteramalmo_active);
  }

  background: #fff url('./assets/images/bg.png') no-repeat fixed center center;
}

.title {
  @apply text-2xl font-thin;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slide-fade-enter-active {
  animation: slide-in 0.3s ease-out;
}

@keyframes slide-in {
  from {
    opacity: 0;
    transform: translateX(-5px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.slide-fade-leave-active {
  animation: slide-out 0.1s ease-out;
}

@keyframes slide-out {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0.5;
    transform: translateX(5px);
  }
}

.transition {
  transition: all 0.1s ease-in;
}

.transition-quart {
  transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
}

#nprogress .bar {
  background: var(--theme_main);
}

.select-skin > input,
.search-skin > input {
  background-color: transparent !important;
  border-width: 0px !important;
}

.search-skin .vs__search::placeholder,
.search-skin .vs__dropdown-toggle,
.search-skin .vs__dropdown-menu {
  @apply px-0.5 py-2 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black bg-gray-100;
}
.select-skin .vs__search::placeholder,
.select-skin .vs__dropdown-toggle,
.select-skin .vs__dropdown-menu {
  @apply px-0.5 py-2 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black bg-transparent;
}

.search-skin .vs__dropdown-menu,
.select-skin .vs__dropdown-menu {
  @apply bg-white;
}

.VuePagination__pagination {
  @apply flex justify-center cursor-pointer gap-1;
  & > .page-item {
    @apply bg-gray-100 hover:bg-gray-200 p-1 px-2 rounded border border-gray-200 font-mono;
    &.active {
      @apply bg-gray-200 hover:bg-gray-300 border border-gray-400;
    }
    &.disabled {
      @apply opacity-40;
    }
  }
}
.VuePagination__count,
.VuePagination__pagination-item-prev-chunk,
.VuePagination__pagination-item-next-chunk {
  @apply hidden;
}

.tooltip {
  @apply text-sm;

  z-index: 10000;
  display: block !important;

  .tooltip-inner {
    padding: 5px 10px 4px;
    color: white;
    background: black;
    border-radius: 0.5rem;
  }

  .tooltip-arrow {
    position: absolute;
    z-index: 1;
    width: 0;
    height: 0;
    margin: 5px;
    border-color: black;
    border-style: solid;
  }

  &[x-placement^='top'] {
    margin-bottom: 5px;

    .tooltip-arrow {
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
      border-width: 5px 5px 0 5px;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      border-left-color: transparent !important;
    }
  }

  &[x-placement^='bottom'] {
    margin-top: 5px;

    .tooltip-arrow {
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
      border-width: 0 5px 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-left-color: transparent !important;
    }
  }

  &[x-placement^='right'] {
    margin-left: 5px;

    .tooltip-arrow {
      top: calc(50% - 5px);
      left: -5px;
      margin-right: 0;
      margin-left: 0;
      border-width: 5px 5px 5px 0;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      border-left-color: transparent !important;
    }
  }

  &[x-placement^='left'] {
    margin-right: 5px;

    .tooltip-arrow {
      top: calc(50% - 5px);
      right: -5px;
      margin-right: 0;
      margin-left: 0;
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
    }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition:
      opacity 0.15s,
      visibility 0.15s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s;
  }
}
</style>
