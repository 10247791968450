<script>
export default {
  props: {
    // eslint-disable-next-line vue/require-prop-types
    value: { required: true },
    type: { type: String, default: 'text' },
  },
  methods: {
    onInput(e) {
      // You can conditionally do this after validation
      this.$emit('input', e.target.value)
    },
  },
}
</script>

<template>
  <input
    :value="value"
    :type="type"
    class="form-input block px-2 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
    @input="onInput"
  />
</template>
