export const SORTERA = 'sortera'
export const SORTERA_MALMO = 'sorteramalmo'
export const SORTERA_OREBRO = 'sorteraorebro'
export const SORTERA_GOTEBORG = 'sorteragoteborg'
export const SORTERA_STOCKHOLM = 'sorterastockholm'
export const BIG_BAG = 'bigbag'
export const AKERISACKEN = 'akerisacken'

export const companies = {
  data() {
    return {
      SORTERA,
      SORTERA_MALMO,
      SORTERA_OREBRO,
      SORTERA_GOTEBORG,
      SORTERA_STOCKHOLM,
      BIG_BAG,
      AKERISACKEN,
    }
  },
}
