<template>
  <div class="badge">
    <slot></slot>
    <sup
      v-show="!hidden && (content || content === 0 || isDot)"
      class="badge__content"
      :class="[
        'badge__content--' + type,
        {
          'is-fixed': $slots.default,
          'is-dot': isDot,
        },
      ]"
      v-text="content"
    >
    </sup>
  </div>
</template>

<script>
export default {
  name: 'BaseBadge',

  props: {
    value: {
      type: [String, Number],
      default: 0,
    },
    max: {
      type: Number,
      default: 0,
    },
    isDot: Boolean,
    hidden: Boolean,
    type: {
      type: String,
      default: 'primary',
      validator(val) {
        return (
          ['primary', 'success', 'warning', 'info', 'danger'].indexOf(val) > -1
        )
      },
    },
  },

  computed: {
    content() {
      if (this.isDot) return

      const value = this.value
      const max = this.max

      if (typeof value === 'number' && typeof max === 'number') {
        return max < value ? `${max}+` : value
      }

      return value
    },
  },
}
</script>

<style lang="scss">
.badge {
  position: relative;
  vertical-align: middle;
  display: inline-block;
}
.badge__content.is-fixed {
  position: absolute;
  top: 0;
  right: 10px;
  transform: translateY(-50%) translateX(100%);
}
.badge__content {
  background-color: #f56c6c;
  border-radius: 10px;
  color: #fff;
  display: inline-block;
  font-size: 12px;
  height: 18px;
  line-height: 18px;
  padding: 0 6px;
  text-align: center;
  white-space: nowrap;
  border: 1px solid #fff;
}
.badge__content.is-fixed.is-dot {
  right: 5px;
}
.badge__content.is-dot {
  right: 0;
  width: 8px;
  height: 8px;
  padding: 0;
  border-radius: 50%;
}
</style>
