<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
  },
}
</script>

<template>
  <div class="flex align-middle">
    <img
      class="mr-2 rounded-full h-6 w-6 my-auto"
      :src="`https://icotar.com/initials/${encodeURIComponent(name)}`"
    />
    {{ name }}
  </div>
</template>
