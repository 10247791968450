import BaseAvatar from '@/components/_base-avatar.vue'
import BaseBadge from '@/components/_base-badge.vue'
import BaseButton from '@/components/_base-button.vue'
import BaseIcon from '@/components/_base-icon.vue'
import BaseInput from '@/components/_base-input.vue'
import BasePhoto from '@/components/_base-photo.vue'

export function useGlobalComponents(Vue) {
  Vue.component('BaseAvatar', BaseAvatar)
  Vue.component('BaseBadge', BaseBadge)
  Vue.component('BaseButton', BaseButton)
  Vue.component('BaseIcon', BaseIcon)
  Vue.component('BaseInput', BaseInput)
  Vue.component('BasePhoto', BasePhoto)
}
