import Vue from 'vue'
import { firestoreAction } from 'vuexfire'
import { DateTime } from 'luxon'
import { db } from '@/plugins/db'

import _orderBy from 'lodash/orderBy'

export const initialState = () => ({
  today: DateTime.local().toISODate(),
  systems: [
    { id: 1, code: 'rx', name: 'Recycling X', vehicleCapacity: 1 },
    { id: 2, code: 'mx', name: 'Material X', vehicleCapacity: 1 },
    { id: 3, code: 'ix', name: 'Industry X', vehicleCapacity: 2 },
  ],

  regions: [],
  people: [],
  tags: [],

  time: [],

  vehicles: [],
  vehicleCategories: [],
  vehicleStations: [],

  orderCategories: [],

  currentSystem: null,
  currentVehicle: null,
  currentPerson: null,
  currentVehicleStation: null,

  filters: {
    schema: [],
    vehicles: [],
    people: [],
    vehicleStations: [],
  },
})

const mutations = {
  SET_STATE(state, payload) {
    for (var key in payload) if (key in state) Vue.set(state, key, payload[key])
  },
  ADD_FILTER(state, { type, filter }) {
    filter.id = state.filters[type].length + 1
    state.filters[type].push(filter)
  },
  REMOVE_FILTER(state, { type, id }) {
    const index = state.filters[type].findIndex(f => f.id === id)
    if (index !== -1) state.filters[type].splice(index, 1)
  },
}

const getters = {
  fsVehicles(state) {
    return _orderBy(state.vehicles, 'code')
  },
  fsVehicleCategories(state) {
    return _orderBy(
      state.vehicleCategories.filter(c => c.enabled),
      'name'
    )
  },
  fsVehicleStations(state) {
    return _orderBy(state.vehicleStations, 'name')
  },
  fsTags(state) {
    return _orderBy(
      state.tags.filter(t => t.enabled),
      'title'
    )
  },
  fsRegions(state) {
    return _orderBy(state.regions, 'name')
  },
  fsPeople(state) {
    return _orderBy(state.people, 'name')
  },
  fsOrderCategories(state) {
    return _orderBy(state.orderCategories, 'rxValue')
  },
}

const actions = {
  init() {},
  bindFsCollection: firestoreAction(({ state, bindFirestoreRef }, name) => {
    return bindFirestoreRef(
      name,
      db.collection(name).where('system', '==', state.currentSystem.code)
    )
  }),
  bindVehicle: firestoreAction(({ state, bindFirestoreRef }, name) => {
    console.log('binding vehicle', `${state.currentSystem.code}-${name}`)
    return bindFirestoreRef(
      'currentVehicle',
      db.collection('vehicles').doc(`${state.currentSystem.code}-${name}`)
    )
  }),
  bindPerson: firestoreAction(({ bindFirestoreRef }, id) => {
    console.log('binding person', id)
    return bindFirestoreRef('currentPerson', db.collection('people').doc(id))
  }),
  bindVehicleStation: firestoreAction(({ bindFirestoreRef }, id) => {
    console.log('binding station', id)
    return bindFirestoreRef(
      'currentVehicleStation',
      db.collection('vehicleStations').doc(id)
    )
  }),
  unbindFs: firestoreAction(({ unbindFirestoreRef }, name) => {
    unbindFirestoreRef(name)
  }),
}

export const appStoreModule = {
  namespaced: true,
  state: initialState(),
  mutations,
  getters,
  actions,
}
