<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library as fontAwesomeIconLibrary } from '@fortawesome/fontawesome-svg-core'
import camelCase from 'lodash/camelCase'

import * as faReply from '@fortawesome/free-solid-svg-icons/faReply'
import * as faSync from '@fortawesome/free-solid-svg-icons/faSync'
import * as faArrowsAlt from '@fortawesome/free-solid-svg-icons/faArrowsAlt'
import * as faEye from '@fortawesome/free-solid-svg-icons/faEye'
import * as faDumpster from '@fortawesome/free-solid-svg-icons/faDumpster'
import * as faDirections from '@fortawesome/free-solid-svg-icons/faDirections'
import * as faUserCircle from '@fortawesome/free-solid-svg-icons/faUserCircle'
import * as faExclamationCircle from '@fortawesome/free-solid-svg-icons/faExclamationCircle'
import * as faInfoCircle from '@fortawesome/free-solid-svg-icons/faInfoCircle'
import * as faBuilding from '@fortawesome/free-solid-svg-icons/faBuilding'
import * as faExclamationTriangle from '@fortawesome/free-solid-svg-icons/faExclamationTriangle'
import * as faAlignCenter from '@fortawesome/free-solid-svg-icons/faAlignCenter'
import * as faCommentAlt from '@fortawesome/free-solid-svg-icons/faCommentAlt'
import * as faCaretLeft from '@fortawesome/free-solid-svg-icons/faCaretLeft'
import * as faArrowAltCircleLeft from '@fortawesome/free-solid-svg-icons/faArrowAltCircleLeft'
import * as faAngleLeft from '@fortawesome/free-solid-svg-icons/faAngleLeft'
import * as faAngleDoubleLeft from '@fortawesome/free-solid-svg-icons/faAngleDoubleLeft'
import * as faAngleRight from '@fortawesome/free-solid-svg-icons/faAngleRight'
import * as faAngleDoubleRight from '@fortawesome/free-solid-svg-icons/faAngleDoubleRight'
import * as faShoppingBag from '@fortawesome/free-solid-svg-icons/faShoppingBag'
import * as faCaretDown from '@fortawesome/free-solid-svg-icons/faCaretDown'
import * as faBarcode from '@fortawesome/free-solid-svg-icons/faBarcode'
import * as faMapMarkedAlt from '@fortawesome/free-solid-svg-icons/faMapMarkedAlt'
import * as faCalendarAlt from '@fortawesome/free-solid-svg-icons/faCalendarAlt'
import * as faTruck from '@fortawesome/free-solid-svg-icons/faTruck'
import * as faUsers from '@fortawesome/free-solid-svg-icons/faUsers'
import * as faTimes from '@fortawesome/free-solid-svg-icons/faTimes'
import * as faPlus from '@fortawesome/free-solid-svg-icons/faPlus'
import * as faHome from '@fortawesome/free-solid-svg-icons/faHome'
import * as faCheck from '@fortawesome/free-solid-svg-icons/faCheck'
import * as faUser from '@fortawesome/free-solid-svg-icons/faUser'
import * as faPen from '@fortawesome/free-solid-svg-icons/faPen'
import * as faHandPointer from '@fortawesome/free-solid-svg-icons/faHandPointer'
import * as faExternalLinkAlt from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt'
import * as faHistory from '@fortawesome/free-solid-svg-icons/faHistory'

// https://fontawesome.com/icons?d=gallery&p=2&m=free
fontAwesomeIconLibrary.add(
  faReply.definition,
  faSync.definition,
  faArrowsAlt.definition,
  faEye.definition,
  faDumpster.definition,
  faDirections.definition,
  faUserCircle.definition,
  faExclamationCircle.definition,
  faInfoCircle.definition,
  faBuilding.definition,
  faExclamationTriangle.definition,
  faAlignCenter.definition,
  faCommentAlt.definition,
  faCaretLeft.definition,
  faArrowAltCircleLeft.definition,
  faAngleLeft.definition,
  faAngleDoubleLeft.definition,
  faAngleRight.definition,
  faAngleDoubleRight.definition,
  faShoppingBag.definition,
  faCaretDown.definition,
  faBarcode.definition,
  faMapMarkedAlt.definition,
  faCalendarAlt.definition,
  faTruck.definition,
  faUsers.definition,
  faTimes.definition,
  faPlus.definition,
  faHome.definition,
  faCheck.definition,
  faUser.definition,
  faPen.definition,
  faHandPointer.definition,
  faExternalLinkAlt.definition,
  faHistory.definition
)

export default {
  components: {
    FontAwesomeIcon,
  },
  inheritAttrs: false,
  props: {
    source: {
      type: String,
      default: 'font-awesome',
    },
    name: {
      type: String,
      required: true,
    },
  },
  computed: {
    // Gets a CSS module class, e.g. iconCustomLogo
    customIconClass() {
      return this.$style[camelCase('icon-custom-' + this.name)]
    },
  },
}
</script>

<template>
  <FontAwesomeIcon
    v-if="source === 'font-awesome'"
    v-bind="$attrs"
    :icon="name"
  />
  <span
    v-else-if="source === 'custom'"
    v-bind="$attrs"
    :class="customIconClass"
  />
</template>
