<script>
export default {
  props: {
    user: {
      default: null,
      type: Object,
    },
    name: {
      default: null,
      type: String,
    },
    data: {
      default: null,
      type: String,
    },
    hideTooltip: {
      default: false,
      type: Boolean,
    },
    round: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    cleanName() {
      if (
        this.name &&
        this.name.includes('@') &&
        this.name.split('@')[0].includes('.')
      ) {
        const match = this.name.match(/^(\w+)\.*\.(\w+)@/)
        return `${match[1]} ${match[2]}`
      }
      return this.name
    },
    imageData() {
      if (this.user && this.user.imageData) return this.user.imageData
      if (this.data && this.data.length > 0) return this.data
      if (this.name && this.name.length > 0)
        return `https://icotar.com/initials/${encodeURIComponent(
          this.cleanName
        )}`
      return 'https://i.imgur.com/L2Py2dG.png'
    },
  },
}
</script>

<template>
  <div
    class="border border-opacity-20 border-gray-400"
    :class="{ 'rounded-full': round, rounded: !round }"
  >
    <slot />
    <img
      :title="cleanName && !hideTooltip ? cleanName : null"
      :src="imageData"
      class="h-full w-full border border-gray-500 border-opacity-20 object-cover"
      :class="{ 'rounded-full': round, rounded: !round }"
    />
  </div>
</template>
