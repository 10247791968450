import { ref } from 'vue'

import { Authentication } from '@sortera/firebase-auth'
import { firebaseApp } from '../plugins/db.js'

const auth = new Authentication(
  {
    clientId: import.meta.env.VITE_AUTH_CLIENT_ID,
    redirectUri: window.location.origin + '/autentisering/logga-in',
    authority: `https://login.microsoftonline.com/${import.meta.env.VITE_AUTH_TENANT_ID}`,
    scopes: [import.meta.env.VITE_AUTH_GATEWAY_SCOPE],
    tenantId: import.meta.env.VITE_AUTH_TENANT_ID,
  },
  firebaseApp
)

const account = ref(null)

async function initializeAuth() {
  if (account.value != null) {
    return
  }
  account.value = await auth.initializeAuth()
  return account.value
}

function login() {
  return auth.login()
}

async function logout() {
  account.value = null
  await auth.logout()
}

export function useAuth() {
  return {
    account,
    initializeAuth,
    login,
    logout,
  }
}
