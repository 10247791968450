import {
  SORTERA_STOCKHOLM,
  SORTERA_GOTEBORG,
  SORTERA_MALMO,
  SORTERA_OREBRO,
} from './companies'

export const STOCKHOLM = 'stockholm'
export const GOTEBORG = 'goteborg'
export const MALMO = 'malmo'
export const OREBRO = 'orebro'

export const regionToCompany = region => {
  switch (region) {
    case STOCKHOLM:
      return SORTERA_STOCKHOLM

    case GOTEBORG:
      return SORTERA_GOTEBORG

    case MALMO:
      return SORTERA_MALMO

    case OREBRO:
      return SORTERA_OREBRO

    default:
      return SORTERA_STOCKHOLM
  }
}

export const regions = {
  data() {
    return {
      STOCKHOLM,
      GOTEBORG,
      OREBRO,
      MALMO,
    }
  },
}
