import Vue from 'vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import BaseIcon from '@/components/_base-icon.vue'

vSelect.props.components.default = () => ({
  Deselect: {
    render: createElement =>
      createElement(BaseIcon, {
        props: {
          name: 'times',
        },
      }),
  },
  OpenIndicator: {
    render: createElement =>
      createElement(BaseIcon, {
        props: {
          name: 'caret-down',
        },
      }),
  },
})

Vue.component('VSelect', vSelect)
