import Vue from 'vue'
import App from './app.vue'
import router from './router'
import store from './state/store'

import './assets/tailwind.css'

import './plugins/polyfill.js'
import './plugins/v-tooltip.js'
import './plugins/vue-cleave.js'
import './plugins/vue-select.js'
import './plugins/vue-drag-drop.js'
import './plugins/vue-pagination-2.js'
import './plugins/vuefire.js'
import { useGlobalComponents } from './plugins/global-components.js'

import './directives/company-theme.js'

import axiosHttp from './plugins/axios.api'
Vue.prototype.$axiosHttp = axiosHttp

Vue.config.productionTip = false

useGlobalComponents(Vue)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
