<script>
export default {
  props: {
    size: {
      default: 'base',
      type: String,
    },
    type: {
      default: 'primary',
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
  },
}
</script>

<template>
  <button
    class="whitespace-nowrap transition-colors duration-50 ease-in-out"
    :class="{
      'text-sm': size === 'sm',
      'text-base': size === 'base',
      'text-lg': size === 'lg',

      'p-1 px-2 text-sm border-b-4 rounded': size === 'sm' && type !== 'text',
      'p-2 px-3 text-base border-b-4 rounded':
        size === 'base' && type !== 'text',
      'p-3 px-4 text-lg border-b-4 rounded': size === 'lg' && type !== 'text',

      'bg-blue-500 border-blue-700 hover:bg-blue-600 hover:border-blue-800':
        type === 'primary' && !disabled,
      'bg-gray-500 border-gray-700 hover:bg-gray-600 hover:border-gray-800':
        type === 'secondary' && !disabled,

      'bg-blue-300 border-blue-400': type === 'primary' && disabled,
      'bg-gray-300 border-gray-500': type === 'secondary' && disabled,

      'cursor-not-allowed': disabled,
      'text-gray-100': type !== 'text',

      'px-1 underline hover:no-underline font-semibold text-gray-600 hover:text-gray-800':
        type === 'text',
    }"
    v-on="$listeners"
  >
    <slot />
  </button>
</template>
