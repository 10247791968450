import store from '@/state/store'
import LoadingView from './views/_loading.vue'
import TimeoutView from './views/_timeout.vue'

export default [
  {
    path: '/',
    redirect: { name: 'select' },
  },
  {
    path: '/system',
    name: 'select',
    meta: { authRequired: true },
    component: () => lazyLoadView(import('./views/select.vue')),
  },
  {
    path: '/system/:systemCode',
    name: 'system',
    redirect: { name: 'scheduling' },
    meta: {
      authRequired: true,
      async beforeResolve(to, from, next) {
        if (!to.params.systemCode && store.state.app.currentSystem) {
          next()
          return
        }
        const system = store.state.app.systems.find(
          sys => sys.code === to.params.systemCode.toLowerCase()
        )
        store.commit('app/SET_STATE', { currentSystem: system })
        next()
      },
    },
    component: () => lazyLoadView(import('./layouts/main.vue')),
    children: [
      {
        path: 'schema',
        name: 'scheduling',
        component: () => lazyLoadView(import('./views/scheduling.vue')),
      },
      {
        path: 'fordon/lista',
        name: 'vehicles',
        component: () => lazyLoadView(import('./views/vehicles.vue')),
      },
      {
        path: 'fordon/:id',
        name: 'vehicle',
        meta: {
          async beforeResolve(to, from, next) {
            store.dispatch('app/bindVehicle', to.params.id)
            next()
          },
        },
        component: () => lazyLoadView(import('./views/vehicle.vue')),
      },
      {
        path: 'medarbetare',
        name: 'people',
        component: () => lazyLoadView(import('./views/people.vue')),
      },
      {
        path: 'medarbetare/:id',
        name: 'person',
        meta: {
          async beforeResolve(to, from, next) {
            store.dispatch('app/bindPerson', to.params.id)
            next()
          },
        },
        component: () => lazyLoadView(import('./views/person.vue')),
      },
      {
        path: 'anlaggning/lista',
        name: 'vehicleStations',
        component: () => lazyLoadView(import('./views/vehicleStations.vue')),
      },
      {
        path: 'anlaggning/:id',
        name: 'vehicleStation',
        meta: {
          async beforeResolve(to, from, next) {
            store.dispatch('app/bindVehicleStation', to.params.id)
            next()
          },
        },
        component: () => lazyLoadView(import('./views/vehicleStation.vue')),
      },

      {
        path: 'cats',
        name: 'orderCategories',
        component: () => lazyLoadView(import('./views/orderCategories.vue')),
      },
    ],
  },
  {
    // Prevent cross-domain storage issues by hosting auth helpers ourselves:
    // https://firebase.google.com/docs/auth/web/redirect-best-practices#self-host-helper-code
    path: '/__/auth/handler',
    component: () => lazyLoadView(import('./views/authHelperRedirect.vue')),
  },
  {
    // Prevent cross-domain storage issues by hosting auth helpers ourselves:
    // https://firebase.google.com/docs/auth/web/redirect-best-practices#self-host-helper-code
    path: '/__/auth/iframe',
    component: () => lazyLoadView(import('./views/authHelperRedirect.vue')),
  },
  {
    path: '*',
    component: () => lazyLoadView(import('./views/_404.vue')),
    // Allows props to be passed to the 404 page through route
    // params, such as `resource` to define what wasn't found.
    props: true,
  },
]

// Lazy-loads view components, but with better UX. A loading view
// will be used if the component takes a while to load, falling
// back to a timeout view in case the page fails to load. You can
// use this component to lazy-load a route with:
//
// component: () => lazyLoadView(import('@views/my-view'))
//
// NOTE: Components loaded with this strategy DO NOT have access
// to in-component guards, such as beforeRouteEnter,
// beforeRouteUpdate, and beforeRouteLeave. You must either use
// route-level guards instead or lazy-load the component directly:
//
// component: () => import('@views/my-view')
//
function lazyLoadView(AsyncView) {
  const AsyncHandler = () => ({
    component: AsyncView,
    // A component to use while the component is loading.
    loading: LoadingView,
    // Delay before showing the loading component.
    // Default: 200 (milliseconds).
    delay: 400,
    // A fallback component in case the timeout is exceeded
    // when loading the component.
    error: TimeoutView,
    // Time before giving up trying to load the component.
    // Default: Infinity (milliseconds).
    timeout: 10000,
  })

  return Promise.resolve({
    functional: true,
    render(h, { data, children }) {
      // Transparently pass any props or children
      // to the view component.
      return h(AsyncHandler, data, children)
    },
  })
}
