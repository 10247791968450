import Vue from 'vue'
import Vuex from 'vuex'
import { vuexfireMutations } from 'vuexfire'
import { appStoreModule } from './modules/app'

export const modules = {
  app: appStoreModule,
}

Vue.use(Vuex)

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules,
  mutations: { ...vuexfireMutations },
})

export default store

store.dispatch('app/init')
