import Vue from 'vue'
import { SORTERA } from '@/utils/companies'

const types = ['main', 'hover', 'focus', 'active']

const defaults = {
  company: SORTERA,
  style: 'background-color',
  type: 'main',
}

Vue.directive('company-color', (el, { arg, value, modifiers }) => {
  const companyCode = arg || defaults.company
  let style = value || defaults.style
  let type = defaults.type

  for (var i = 0; i < types.length; i++) {
    var t = types[i]
    if (modifiers[t]) {
      type = t
    }
  }

  el.style[style] = `var(--${companyCode}_${type})`
})
